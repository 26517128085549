import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-385d1a36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "components-container" }
const _hoisted_2 = {
  target: "_blank",
  class: "link-type",
  href: "https://armour.github.io/vue-typescript-admin-docs/features/components/rich-editor.html"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tinymce = _resolveComponent("Tinymce")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("aside", null, [
      _createTextVNode(_toDisplayString(_ctx.t('components.tinymceTips')) + " ", 1),
      _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.t('components.documentation')), 1)
    ]),
    _createElementVNode("div", null, [
      (_ctx.tinymceActive)
        ? (_openBlock(), _createBlock(_component_Tinymce, {
            key: 0,
            value: _ctx.content,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.content) = $event)),
            height: 400,
            onInput: _ctx.input
          }, null, 8, ["value", "onInput"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: "editor-content",
      innerHTML: _ctx.content
    }, null, 8, _hoisted_3)
  ]))
}